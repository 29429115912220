<template>
  <div>
    <b-card
      no-body
      border-variant="light"
      class="custom-shadow"
    >
      <div class="m-2">
        <template>
          <div class="p-0">
            <b-row>
              <b-col
                v-for="item in data.layanan"
                :key="item.nama_layanan"
                cols="12"
                sm="6"
              >
                <small class="text-secondary">{{ vertikalLayanan(data.vertikal) }}</small>
                <h6 class="text-primary">
                  {{ item.nama_layanan }}
                </h6>
                <small>{{ getDate(data.pesanan_terjadwal) }}</small>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                class="text-lg-right"
              >
                <b-badge
                  pill
                  :variant="statusOrder(data.status).variant"
                >
                  {{ statusOrder(data.status).display }}
                </b-badge>
                <h6 class="mt-50">
                  {{ data.nomor_order }}
                </h6>

              </b-col>
            </b-row>
          </div>
        </template>

        <b-card-body body-border-variant="success">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <small class="secondary">Customer</small>
              <h6 class="m-0 mt-25">
                {{ data.nama_customer }}
              </h6>
              <small>{{ data.no_hp_customer }}</small>
              <h6 class="m-0 mt-25">
                Unique ID*
              </h6>
              <small>{{ id }}</small>

            </b-col>
            <b-col
              v-if="data.uid_mitra"
              cols="12"
              md="6"
              class="align-items-end justify-content-end"
            >
              <small class="text-secondary">Mitra</small>
              <h6 class="m-0 mt-25">
                {{ data.nama_mitra }}
              </h6>
              <small>{{ data.no_hp_mitra }}</small>
            </b-col>
          </b-row>
        </b-card-body>

        <div class="mt-1">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <span class="text-secondary">{{ data.pembayaran }}</span>
              <h6 class="m-0 mt-25 mb-1 font-weight-bolder">
                {{ data.total }}
              </h6>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="d-flex justify-content-center"
            >
              <h6 class=" mt-1 justify-content-center mr-1">
                Rating
              </h6>
              <!-- <span class="text-secondary te">Rating :</span> -->
              <b-form-rating
                id="rating-sm-no-border"
                v-model="valueRating"
                inline
                no-border
                readonly
                variant="success"
                class="p-0"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <router-link
        :to="{ name: 'DetailPesanan', params: { id: id } }"
      >
        <b-button
          block
          variant="success"
        >
          Detail Pesanan
        </b-button>
      </router-link>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormRating,
  BBadge,
  BCardBody,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormRating,
    BBadge,
    BCardBody,
    BButton,
  },
  props: ['id', 'data'],
  data() {
    return {
      valueRating: '',
    }
  },
  created() {
    this.getRating()
  },
  methods: {
    getRating() {
      if (this.data.rating_mitra !== undefined && this.data.rating_mitra !== null) {
        this.valueRating = this.data.rating_mitra
      }
    },
    statusOrder(status) {
      if (status === 0) return { variant: 'light-danger', display: 'Membuat proses' }
      if (status === 1) return { variant: 'light-primary', display: 'Mencari Mitra' }
      if (status === 2) return { variant: 'warning', display: 'Terjadwal' }
      if (status === 3) return { variant: 'light-primary', display: 'Mitra dalam perjalanan' }
      if (status === 4) return { variant: 'light-primary', display: 'Mitra sudah sampai' }
      if (status === 5) return { variant: 'light-primary', display: 'Sedang mengerjakan' }
      if (status === 6) return { variant: 'light-primary', display: 'Menunggu pembayaran' }
      if (status === 7) return { variant: 'light-primary', display: 'Selesai' }
      if (status === 8) return { variant: 'danger', display: 'Dibatalkan oleh customer' }
      if (status === 9) return { variant: 'danger', display: 'Dibatalkan oleh sistem' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric',
      })
    },
    vertikalLayanan(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupuntur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }
      return 'Layanan belum Diisi'
    },
    detailPesanan(id) {
      /* eslint-disable object-shorthand */
      this.$router.push({ name: 'DetailPesanan', params: { id: id } })
    },
  },
}
</script>

<style scoped>
  .custom-shadow {
    box-shadow: rgba(90, 108, 126, 0.1) 0px 8px 24px !important;
  }
</style>
