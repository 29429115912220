<template>
  <div>
    <app-breadcrumb />
    <b-card no-body>
      <div>
        <b-button
          v-b-toggle.sidebar-right
          variant="success"
          class="d-flex m-2"
        >
          <feather-icon
            icon="FilterIcon"
            size="14"
          />
          <span class="ml-1">Filter</span>
        </b-button>
      </div>
      <b-sidebar
        id="sidebar-right"
        title="Filter"
        right
        shadow
      >
        <div class="px-3 py-2">
          <!-- filter perpage -->
          <b-col
            class="d-flex align-content-center justify-content-start mb-md-0"
          >
            <label>Tampilkan</label>
            <v-select
              v-model="perPage"
              dir="ltr"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <label>data</label>
          </b-col>
          <b-col class="mt-1">
            <!-- filter status -->
            <v-select
              v-model="statusFilter"
              dir="ltr"
              label="label"
              :clearable="false"
              :options="statusOptions"
              placeholder="Filter Status Order"
              @option:selected="filterStatus()"
            />
          </b-col>
          <b-col class="mt-1">
            <b-button
              variant="primary"
              block
              class="d-flex justify-content-between p-50"
              @click="openTampilanOrder()"
            >
              <!-- eslint-disable vue/singleline-html-element-content-newline -->
              <p class="m-0">Cari Pesanan</p>
              <feather-icon
                icon="SearchIcon"
                size="16"
                class="mr-25"
                style="padding-top: 2px"
              />
            </b-button>
          </b-col>
        </div>
      </b-sidebar>
      <div class="m-2">
        <b-row>
          <b-col
            v-for="order in listOrder"
            :key="order.id"
            cols="12"
            lg="6"
            md="12"
          >
            <pesanan-card
              :id="order.id"
              :data="order.data"
            />
          </b-col>
        </b-row>

        <div
          v-if="loadMorebtn"
          class="d-flex mb-2"
        >

          <!-- Load More -->

          <b-button
            class="btn mx-auto btn-primary"
            @click="loadMore()"
          >
            <feather-icon icon="ChevronDownIcon" />
            Lihat lebih banyak
          </b-button>
        </div>
        <div>
          <vue-bottom-sheet
            ref="tampilkanSearchPesanan"
            class="p-1"
            :rounded="false"
          >
            <div class="bottom-sheet">
              <div class="d-flex justify-content-between align-item-center">
                <h4>Cari Pesanan</h4>
                <feather-icon
                  icon="XIcon"
                  size="28"
                  class="text-primary"
                  @click="closeTampilanOrder()"
                />
              </div>
              <ais-instant-search
                :search-client="searchClient"
                index-name="pesanan"
              >
                <ais-search-box
                  placeholder="Cari pesanan (bisa dengan nama mitra atau nama customer)"
                  :class-names="{
                    'ais-SearchBox-input': 'form-control',
                    'ais-SearchBox-submit': 'd-none',
                    'ais-SearchBox-reset': 'd-none',
                  }"
                  class="mt-1"
                >
                  <div slot="submit-icon">Cari</div>
                </ais-search-box>
                <ais-hits
                  :class-names="{
                    'ais-Hits-list': 'hitsList',
                  }"
                >
                  <div
                    slot="item"
                    slot-scope="{ item }"
                    class="mt-1"
                  >
                    <b-card
                      border-variant="primary"
                      class="m-0"
                    >
                      <b-row>
                        <b-col
                          cols="12"
                          md="4"
                        >
                          =======
                          <b-col
                            cols="12"
                            md="4"
                          >

                            <div
                              class="card-body d-flex justify-content-between p-0"
                            >
                              <div class="d-inline-block">
                                <h6
                                  class="text-primary font-weight-bolder"
                                  style="cursor: pointer"
                                  @click="detailPesanan(item.objectID)"
                                >
                                  {{ item.nama_customer }}
                                </h6>
                                <p class="text-secondary m-0">
                                  {{ item.no_hp_customer }}
                                </p>
                                <small
                                  v-for="order in item.layanan"
                                  :key="order.objectID"
                                >
                                  {{ order.nama_layanan }}
                                </small>
                              </div>
                            </div>
                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                            class="ml-0"
                          >
                            <div
                              class="card-body d-flex justify-content-between p-0"
                            >
                              <div class="d-inline-block">
                                <h6 class="text-warning font-weight-bold">
                                  {{ item.nama_mitra }}
                                </h6>
                                <p class="text-secondary m-0">
                                  {{ item.no_hp_mitra }}
                                </p>
                                <small>{{ item.waktu }}</small>
                              </div>
                            </div>
                          </b-col>

                          <b-col
                            cols="12"
                            md="4"
                            class="text-right"
                          >

                            <b-badge variant="success">
                              {{ item.nomor_order }}
                            </b-badge>
                          </b-col>
                        </b-col></b-row>
                    </b-card>
                  </div>
                </ais-hits>
              </ais-instant-search>
            </div>
          </vue-bottom-sheet>
        </div>
      </div></b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BBadge,
  BSidebar,
  VBToggle,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import { db } from '@/firebase'
import permission from '@/permission'
import regionJson from '@/db/region.json'
import PesananCard from './PesananCard.vue'

export default {
  components: {
    AppBreadcrumb,
    BCard,
    BCol,
    BRow,
    BBadge,
    BButton,
    vSelect,
    BSidebar,

    VueBottomSheet,
    AisInstantSearch,
    AisSearchBox,
    PesananCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      searchClient: algoliasearch(
        '4LASHL8CBV',
        '0f873fc12e5ca3f430fbe1014a65c384',
      ),
      perPage: 40,
      perPageOptions: [40, 80, 120],
      loadMorebtn: true,
      listOrder: [],
      allOrder: [],
      isSortDirDesc: false,
      leaderData: '',
      listMitra: [],
      lastVisible: null,
      region: regionJson,
      permission: permission.access,
      loading: true,
      showOverlay: true,
      filteredMitra: [],
      indexSearch: [],
      statusFilter: { value: 0, label: 'Semua status' },
      statusOptions: [
        { value: 0, label: 'Semua Status' },
        { value: 1, label: 'Mencari mitra' },
        { value: 2, label: 'Terjadwal' },
        { value: 3, label: 'Mitra dalam perjalanan' },
        { value: 4, label: 'Mitra sudah sampai' },
        { value: 5, label: 'Sedang mengerjakan' },
        { value: 6, label: 'Menunggu pembayaran' },
        { value: 7, label: 'Selesai' },
        { value: 8, label: 'Dibatalkan oleh customer' },
        { value: 9, label: 'Dibatal oleh sistem' },
      ],
    }
  },
  created() {
    this.leaderData = JSON.parse(localStorage.getItem('userData'))

    if (this.leaderData.permission.mitra.area[0] !== 'all') {
      this.leaderData.permission.mitra.area.forEach(area => {
        const layanan = area.split('-')[0]
        const region = area.split('-')[1]

        this.indexSearch.push({
          area: region,
          leader: this.leaderData.leader_id,
          vertikal: layanan,
        })
      })

      this.getMitra()
    }

    this.getOrder()
  },
  methods: {
    async getMitra() {
      this.showOverlay = true
      this.loadMorebtn = true
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getMitra',
        method: 'POST',
        data: {
          limit: 120,
          lastVisible: null,
          regionLeader: this.indexSearch,
        },
      }

      this.loading = false

      await this.$axios(options)

        .then(res => {
          this.lastVisible = res.data.lastVisible
          this.listMitra = [...new Set(res.data.mitra)]

          this.getOrder()

          if (res.data.mitra.length === 0) {
            this.loadMorebtn = false
          }
        })
        .catch(() => {
          this.loading = false
          this.showOverlay = false
        })
    },
    getOrder() {
      /* eslint-disable no-unused-vars */
      const lastVisible = this.listOrder.length
      const limit = this.perPage <= 10 ? 10 : this.perPage
      this.loadMorebtn = true
      const query = db.collection('orders').orderBy('waktu', 'desc')

      const orderList = []

      if (this.leaderData.permission.mitra.area[0] !== 'all') {
        this.listMitra.forEach(mitra => {
          if (mitra.uid !== null && mitra.uid !== undefined) {
            query
              .limit(limit)

              .where('uid_mitra', '==', mitra.uid)
              .get()
              .then(querySnapshot => {
                querySnapshot.docs.forEach(doc => {
                  orderList.push({
                    id: doc.id,
                    data: doc.data(),
                  })
                })

                this.listOrder = [...new Set(orderList)]
                this.allOrder = [...new Set(orderList)] // for filter
              })
          }
        })
      } else {
        query
          .limit(limit)
          .get()

          .then(querySnapshot => {
            querySnapshot.docs.forEach(doc => {
              orderList.push({
                id: doc.id,
                data: doc.data(),
              })
            })

            this.listOrder = [...new Set(orderList)]
            this.allOrder = [...new Set(orderList)] // for filter
          })
      }
    },
    loadMore() {
      if (this.listOrder.length === this.perPage) {
        this.perPage += 40
        this.getOrder()
      } else {
        this.loadMorebtn = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      }
    },
    filterStatus() {
      /* eslint-disable arrow-body-style */
      this.showOverlay = true
      if (this.statusFilter.value === 0) {
        this.getOrder()
      } else {
        const filtered = this.allOrder.filter(item => {
          return item.data.status === this.statusFilter.value
        })
        this.listOrder = [...new Set(filtered)]
        this.showOverlay = false
      }
    },
    openTampilanOrder() {
      this.$refs.tampilkanSearchPesanan.open()
    },
    closeTampilanOrder() {
      this.$refs.tampilkanSearchPesanan.close()
    },
    detailPesanan(idFirestore) {
      this.closeTampilanOrder()
      this.$router.push({ name: 'DetailPesanan', params: { id: idFirestore } })
    },
  },

}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.bottom-sheet {
  padding: 16px 16px 200px 16px;
}
.hitsList {
  list-style-type: none !important;
  padding-left: 0;
}
</style>
